import * as React from "react";
import Navigation from "../components/Navigation";
import AboutSeo from "../components/SEO/AboutSeo";
import "../styles/style.css";
import Dale from "../images/Dale.png";
import Keiron from "../images/Keiron.png";

const About = () => {
  return (
    <main>
      <div className="content">
        <AboutSeo></AboutSeo>
        <Navigation></Navigation>
        <div className="about__section-1">
          <div>
            <div className="glass">
              <h1>About us</h1>
            </div>
          </div>
          <div className="bullet-circle__container">
            <div className="bullet-circle"></div>
            <p>
              The collaborators and UXC Portal founders have a passion for
              learning and for helping the design community.
            </p>
          </div>
        </div>
        <div className="about__profiles-section">
          <div className="about__profile">
            <div className="glass">
              <h2>Dale Husband</h2>
            </div>
            <img src={Dale} alt="Dale" />
            <div className="about__bio">
              An up and coming UX designer based in Piemonte, Italy.
              <br />
              <br /> After studying CISCO Networking security and IT Hardware at
              Burnley College in the UK, he worked on a Microsoft Office 365
              project in Germany. Since focusing on UX Design, decided to create
              the UXC Portal to help others in the UX community.
            </div>
          </div>
          <div className="about__profile">
            <div className="glass">
              <h2>Keiron Peel</h2>
            </div>
            <img src={Keiron} alt="Keiron" />
            <div className="about__bio">
              A web developer and programmer, Keiron has been based in Portugal
              for the last year and a half.
              <br />
              <br /> With a passion for coding and design, decided to
              collaborate for the creation of The UXC Portal to help the wider
              design community.
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
